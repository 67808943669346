<template>
    <div>
        <v-btn title="Import using CSV file" v-if="isAllowed('createImportFile')" :style="theme"
            :to="{ name: 'ImportFile', params: { importFile: importData } }">
            <v-icon class="mx-2">mdi-file-import</v-icon>
            Import
        </v-btn>
    </div>
</template>

<script>
export default {
    props: ['importData']
}
</script>

<style lang="scss" scoped></style>