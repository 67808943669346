<template>
    <v-card :class="isMobile ? 'mx-1' : 'mx-4'" flat>
        <v-card-title>
            <v-row align="end">
                <v-col cols="12" sm="4">
                    <slot name="title">{{ title }}</slot>
                </v-col>
                <v-col cols="12" sm="8" align="top">
                    <div class="text-right" :class="!isMobile ? 'd-flex' : ''">
                        <slot name="searchbar">
                            <v-text-field v-model="search" @blur="searchData($event.target.value)"
                                @keypress.enter="searchData($event.target.value)" @click:clear="searchData('')"
                                clearable label="Search" single-line hide-details>
                                <template v-slot:append>
                                    <v-btn text @click="searchData(search)"><v-icon>mdi-magnify</v-icon></v-btn>
                                </template>
                            </v-text-field>
                        </slot>
                        <slot name="action-buttons">
                            <div class="d-flex align-center justify-end" :class="isMobile ? 'mt-4' : ''">
                                <v-btn medium :class="isMobile ? '' : 'ml-4'" v-if="showAddButton" @click="$emit('add')"
                                    :style="theme" title="Add"><v-icon>mdi-plus</v-icon><label
                                        v-if="isMobile">Add</label></v-btn>
                                <slot name="extended-action-buttons">
                                </slot>
                            </div>
                        </slot>
                    </div>
                </v-col>
            </v-row>
        </v-card-title>
        <slot name="table" :data="items">
            <v-data-table :loading="loading" :headers="customHeaders" :items="items" :items-per-page="size"
                :hide-default-footer="true">
                <template v-slot:item="{ item, index, customHeaders }">
                    <slot name="item" :item="item">
                        <tr>
                            <td v-for="(column, colIndex) in headers" :key="colIndex"
                                :class="setAlignment(column.align)"
                                @click="showViewButton && column.value != 'action' ? $emit('view', item) : ''">
                                <slot :name="column.value" :item="item" :value="item[column.value]">
                                    <template v-if="column.value == 'action'">
                                        <v-icon @click="$emit('edit', item)" v-if="showEditButton">mdi-pencil</v-icon>
                                        <v-icon @click="$emit('delete', item)"
                                            v-if="showDeleteButton">mdi-delete</v-icon>
                                    </template>
                                    <template v-else>
                                        {{ readValue(item, column.value) }}
                                    </template>
                                    <slot name="extended-action"></slot>
                                </slot>
                            </td>
                        </tr>
                    </slot>
                </template>
            </v-data-table>
        </slot>
        <slot name="pagination">
            <v-row align="center">
                <v-col cols="8" sm="10" align="end">
                    <strong>{{ currentlyDisplayedRecords }} of {{ totalRecords }}</strong>
                </v-col>
                <v-col align="end" cols="4" sm="2">
                    <v-spacer></v-spacer>
                    <v-select label="Records Per Page" :items="[10, 20, 50, 100]" v-model="size" @change="loadData()"
                        width="100px"></v-select>
                </v-col>
            </v-row>
            <div class="text-right">
            </div>
            <v-spacer></v-spacer>
            <v-pagination v-model="page" :length="pageCount" @next="loadData()" @previous="loadData()"
                @input="loadData()"></v-pagination>
        </slot>
    </v-card>
</template>

<script>
import appConstants from '../../utils/appConstants'
import t from 'typy'
export default {
    props: {
        endpoint: {
            type: String,
        },
        headers: {
            type: Array,
            default: [],
        },
        title: {
            type: String,
        },
        searchPlaceholder: {
            type: String,
            default: "Search"
        },
        showActionsColumn: {
            type: Boolean,
            default: true,
        },
        showAddButton: {
            type: Boolean,
            default: true,
        },
        showEditButton: {
            type: Boolean,
            default: true,
        },
        showDeleteButton: {
            type: Boolean,
            default: true,
        },
        showViewButton: {
            type: Boolean,
            default: true,
        },
        sortBy: {
            type: String
        }
    },
    data() {
        return {
            page: 1,
            size: appConstants.PAGE_SIZE,
            pageCount: 0,
            items: [],
            loading: false,
            sort: this.sortBy || `+${this.headers[0].value}`,
            search: '',
            totalRecords: 0
        }
    },
    computed: {
        customHeaders() {
            if (this.showActionsColumn && !!!this.headers.find(rec => rec.value == 'action')) {
                let customHeaders = [...this.headers]
                customHeaders.push({ text: 'Actions', value: 'action', align: 'right' })
                return customHeaders
            } else return this.headers
        },
        query() {
            let query = `pageNo=${this.page}&size=${this.size}`
            if (this.sortBy) {
                query += `&sort=${this.sort}`
            }
            if (this.search) {
                query += `&searchText=${this.search}`
            }
            return query
        },
        currentlyDisplayedRecords() {
            let total = this.page * this.size
            return total > this.totalRecords ? this.totalRecords : total
        }
    },
    created() {
        this.checkPageState(this.endpoint);
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            try {
                this.loading = true
                let response;
                if (this.endpoint.includes('?'))
                    response = await this.getItem(`${this.endpoint}&${this.query}`);
                else
                    response = await this.getItem(`${this.endpoint}?${this.query}`);
                this.items = response.data;
                this.pageCount = response.meta.totalPages;
                this.totalRecords = response.meta.totalRecords;
                this.updatePageState(this.endpoint, this.page)
            } catch (error) {
                console.log('' + error);
            } finally {
                this.loading = false
            }
        },
        readValue(object, property) {
            return t(object, property).input
        },
        async searchData(searchText) {
            try {
                this.search = searchText;
                this.page = 1;
                this.loadData()
            } catch (error) {
                console.log(error);
            }
        },
        checkPageState(apiCall) {
            if (this.$store.getters.pageState) {
                let index = this.$store.getters.pageState.findIndex(rec => rec.api == apiCall.split("?")[0])
                this.page = index > -1 ? this.$store.getters.pageState[index].pageNo : 1
            }
            else
                this.page = 1
        },
        updatePageState(apiCall, newValue) {
            let pageState = this.$store.getters.pageState
            let pageData = {
                api: apiCall.split("?")[0],
                pageNo: newValue
            }
            if (!pageState)
                this.$store.dispatch("actionUpdatePageState", [pageData])
            else {
                let index = pageState.findIndex(rec => rec.api == apiCall.split("?")[0])
                if (index > -1) {
                    pageState[index].pageNo = newValue
                }
                else
                    pageState.push(pageData)
                this.$store.dispatch("actionUpdatePageState", pageState)
            }
        },
        setAlignment(alignment) {
            switch (alignment) {
                case 'left': return 'text-left'
                case 'right': return 'text-right'
                case 'center': return 'text-center'
            }
        }
    },
}
</script>

<style lang="scss" scoped></style>